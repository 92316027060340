"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var defaultAppData = {
  'jsoneditor': {
    'schema': 'root',
    'name': 'Root'
  },
  'props': {
    'settings': {
      'jsoneditor': {
        'schema': 'settings',
        'name': 'Settings'
      },
      'props': {
        'settings': [{
          'title': 'Boligvelger',
          'view_id': 'area',
          'default_effect': 'zoom',
          'translations': [{
            'text': 'available',
            'translate': 'Available'
          }, {
            'text': 'reserved',
            'translate': 'Reserved'
          }, {
            'text': 'sold',
            'translate': 'Sold'
          }, {
            'text': 'download',
            'translate': 'Download'
          }, {
            'text': 'info',
            'translate': 'Info'
          }, {
            'text': 'previously',
            'translate': 'Previously'
          }, {
            'text': 'filter',
            'translate': 'Filter'
          }, {
            'text': 'area filter',
            'translate': 'Area filter'
          }, {
            'text': 'building filter',
            'translate': 'Building filter'
          }, {
            'text': 'flat filter',
            'translate': 'Flat filter'
          }, {
            'text': 'share',
            'translate': 'Share'
          }, {
            'text': 'selected view',
            'translate': 'Selected view'
          }, {
            'text': 'player link',
            'translate': 'Player link'
          }, {
            'text': 'embed',
            'translate': 'Embed'
          }, {
            'text': 'tokens',
            'translate': 'Tokens'
          }, {
            'text': 'Object name',
            'translate': 'Object name'
          }, {
            'text': 'Status',
            'translate': 'Status'
          }, {
            'text': 'Number of flats',
            'translate': 'Number of flats'
          }, {
            'text': 'Price',
            'translate': 'Price'
          }, {
            'text': 'Floor',
            'translate': 'Floor'
          }, {
            'text': 'Size',
            'translate': 'Size'
          }, {
            'text': 'Rooms',
            'translate': 'Rooms'
          }, {
            'text': 'PDF download',
            'translate': 'PDF download'
          }],
          'colors': {
            'player': {},
            'area': {
              'available': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(72,100,116,0.7)',
                'hoverStrokeColor': 'rgba(255,255,255,1)'
              },
              'reserved': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(230,207,52,0.7)',
                'hoverStrokeColor': 'rgba(255,255,255,1)'
              },
              'sold': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(204,89,60,0.7)',
                'hoverStrokeColor': 'rgba(255,255,255,1)'
              }
            },
            'building': {
              'available': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(72,100,116,0.6)',
                'hoverStrokeColor': 'rgba(255,255,255,0.5)'
              },
              'reserved': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(230,207,52,0.7)',
                'hoverStrokeColor': 'rgba(255,255,255,0)'
              },
              'sold': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(204,89,60,0.7)',
                'hoverStrokeColor': 'rgba(255,255,255,0.5)'
              }
            },
            'flat': {
              'available': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(76,127,102,0.7)',
                'hoverStrokeColor': 'rgba(255,255,255,0.7)'
              },
              'reserved': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(218,215,157,0.7)',
                'hoverStrokeColor': 'rgba(255,255,255,0.7)'
              },
              'sold': {
                'defaultColor': 'rgba(0,0,0,0)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(150,92,74,0.7)',
                'hoverStrokeColor': 'rgba(255,255,255,0.7)'
              }
            },
            'gallery': {
              'gallery1': {
                'defaultColor': 'rgba(59,61,65,0.8)',
                'defaultStrokeColor': 'rgba(0,0,0,0)',
                'hoverColor': 'rgba(59,61,65,1)',
                'hoverStrokeColor': 'rgba(0,0,0,0)'
              }
            }
          }
        }]
      }
    },
    'metas': {
      'jsoneditor': {
        'schema': 'meta',
        'name': 'Meta editor'
      },
      'props': {
        'metas': [{
          'key': 'key',
          'name': 'Object ID',
          'show': [],
          'order': 0,
          'required': true,
          'type': 'string',
          'enabled': ['area', 'building', 'flat'],
          'default': 'object_id',
          'disabled': true,
          'validations': [{
            'name': 'min',
            'props': {
              'value': 1,
              'type': 'string'
            }
          }, {
            'name': 'max',
            'props': {
              'value': 250,
              'type': 'string'
            }
          }, {
            'name': 'type',
            'props': {
              'value': ['string']
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'Object type',
          'show': [],
          'order': 0,
          'key': 'type',
          'required': true,
          'type': 'string',
          'enabled': ['area', 'building', 'flat'],
          'component': 'select',
          'default': 'area',
          'options': [{
            'label': 'Area',
            'value': 'area'
          }, {
            'label': 'Building',
            'value': 'building'
          }, {
            'label': 'Flat',
            'value': 'flat'
          }],
          'validations': [{
            'name': 'type',
            'props': {
              'value': ['object']
            }
          }, {
            'name': 'arrayin',
            'props': {
              'value': ['area', 'building', 'flat']
            }
          }, {
            'name': 'min',
            'props': {
              'value': 1,
              'type': 'object'
            }
          }, {
            'name': 'max',
            'props': {
              'value': 1,
              'type': 'object'
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'Object name',
          'order': 0,
          'key': 'name',
          'show': ['table', 'tooltip', 'popup'],
          'required': true,
          'type': 'string',
          'enabled': ['area', 'building', 'flat'],
          'default': 'Object name',
          'validations': [{
            'name': 'min',
            'props': {
              'value': 1,
              'type': 'string'
            }
          }, {
            'name': 'max',
            'props': {
              'value': 250,
              'type': 'string'
            }
          }, {
            'name': 'type',
            'props': {
              'value': ['string']
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'Status',
          'show': ['tooltip', 'popup'],
          'order': 0,
          'key': 'status',
          'required': true,
          'enabled': ['area', 'building', 'flat'],
          'type': 'string',
          'component': 'select',
          'default': 'available',
          'options': [{
            'label': 'Available',
            'value': 'available'
          }, {
            'label': 'Reserved',
            'value': 'reserved'
          }, {
            'label': 'Sold',
            'value': 'sold'
          }],
          'validations': [{
            'name': 'type',
            'props': {
              'value': ['object']
            }
          }, {
            'name': 'arrayin',
            'props': {
              'value': ['available', 'reserved', 'sold']
            }
          }, {
            'name': 'min',
            'props': {
              'value': 1,
              'type': 'object'
            }
          }, {
            'name': 'max',
            'props': {
              'value': 1,
              'type': 'object'
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'Description',
          'show': ['popup'],
          'order': 1,
          'key': 'description',
          'required': false,
          'enabled': ['flat'],
          'type': 'string',
          'default': '',
          'validations': [{
            'name': 'min',
            'props': {
              'value': 1,
              'type': 'string'
            }
          }, {
            'name': 'max',
            'props': {
              'value': 2500,
              'type': 'string'
            }
          }, {
            'name': 'type',
            'props': {
              'value': ['string']
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'Number of flats',
          'show': ['table', 'tooltip', 'popup'],
          'order': 2,
          'required': false,
          'key': 'flats_number',
          'enabled': ['building'],
          'type': 'number',
          'default': 0,
          'validations': [{
            'name': 'type',
            'props': {
              'value': ['number']
            }
          }, {
            'name': 'min',
            'props': {
              'value': 0,
              'type': 'number'
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'Price',
          'show': ['table', 'tooltip', 'popup', 'filter'],
          'order': 2,
          'required': false,
          'key': 'price',
          'endfix': 'EUR',
          'format': ['price', 'replace'],
          'enabled': ['flat'],
          'type': 'number',
          'default': 0,
          'validations': [{
            'name': 'type',
            'props': {
              'value': ['number']
            }
          }, {
            'name': 'min',
            'props': {
              'value': 0,
              'type': 'number'
            }
          }]
        }, {
          'name': 'Floor',
          'show': ['tooltip', 'popup', 'filter'],
          'order': 4,
          'required': false,
          'key': 'floor',
          'enabled': ['flat'],
          'type': 'number',
          'default': 0,
          'validations': [{
            'name': 'type',
            'props': {
              'value': ['number']
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'Size',
          'show': ['tooltip', 'popup', 'table', 'filter'],
          'order': 6,
          'required': false,
          'key': 'size',
          'enabled': ['flat'],
          'type': 'number',
          'default': 0,
          'validations': [{
            'name': 'type',
            'props': {
              'value': ['number']
            }
          }, {
            'name': 'min',
            'props': {
              'value': 0,
              'type': 'number'
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'Rooms',
          'show': ['tooltip', 'popup', 'table', 'filter'],
          'order': 8,
          'required': false,
          'key': 'rooms',
          'enabled': ['flat'],
          'type': 'number',
          'default': 0,
          'validations': [{
            'name': 'type',
            'props': {
              'value': ['number']
            }
          }, {
            'name': 'min',
            'props': {
              'value': 0,
              'type': 'number'
            }
          }],
          'endfix': '',
          'format': ''
        }, {
          'name': 'PDF download',
          'show': ['popup'],
          'order': 102,
          'required': false,
          'key': 'pdf_download',
          'format': 'download',
          'enabled': ['flat'],
          'type': 'string',
          'default': '',
          'validations': [{
            'name': 'urlorempty',
            'props': {}
          }, {
            'name': 'pathorempty',
            'props': {
              'value': ['pdf']
            }
          }],
          'endfix': ''
        }, {
          'name': 'Custom meta',
          'show': [],
          'order': 40,
          'required': false,
          'type': 'string',
          'enabled': ['building'],
          'key': 'custom_meta_1',
          'endfix': '',
          'format': ''
        }, {
          'name': 'Custom meta',
          'show': [],
          'order': 41,
          'required': false,
          'type': 'string',
          'enabled': ['flat'],
          'key': 'custom_meta_2',
          'endfix': '',
          'format': ''
        }]
      }
    },
    'objects': {
      'jsoneditor': {
        'schema': 'objects',
        'name': 'Object editor'
      },
      'props': {
        'objects': [{
          'key': 'area',
          'name': 'Area',
          'type': 'area',
          'status': 'available'
        }, {
          'key': 'object_1',
          'name': 'Building 1',
          'type': 'building',
          'status': 'available',
          'flats_number': 6,
          'custom_meta_1': 'Buiding custom data'
        }, {
          'key': 'object_2',
          'name': 'Flat 1-1',
          'type': 'flat',
          'status': 'available',
          'price': 100000,
          'floor': 1,
          'size': 72,
          'rooms': 3,
          'pdf_background': 'https://a.com/a.jpg',
          'pdf_download': 'https://rematech.carco.hu/demo/flatchooser-app-3/pdf-sample.pdf',
          'custom_meta_2': 'Flat 1 custom data'
        }, {
          'key': 'object_3',
          'name': 'Flat 1-2',
          'type': 'flat',
          'status': 'reserved',
          'price': 100000,
          'floor': 2,
          'size': 72,
          'rooms': 3,
          'pdf_background': 'https://a.com/a.jpg',
          'pdf_download': 'https://rematech.carco.hu/demo/flatchooser-app-3/pdf-sample.pdf',
          'custom_meta_2': 'Flat 2 custom data'
        }, {
          'key': 'object_4',
          'name': 'Flat 1-3',
          'type': 'flat',
          'status': 'sold',
          'price': 100000,
          'floor': 3,
          'size': 72,
          'rooms': 3,
          'pdf_background': 'https://a.com/a.jpg',
          'pdf_download': 'https://rematech.carco.hu/demo/flatchooser-app-3/pdf-sample.pdf',
          'custom_meta_2': 'Flat 3 custom data'
        }]
      }
    },
    'views': {
      'jsoneditor': {
        'schema': 'views',
        'name': 'View editor'
      },
      'props': {
        'views': [{
          'name': '',
          'object_id': 'area',
          'key': 'area',
          'elements': [{
            'component': 'Root',
            'props': {
              'id': 'root_area',
              'scale': false,
              'width': 1920,
              'height': 1080,
              'customStyle': {
                'backgroundColor': 'rgba(0,0,0,0)'
              },
              'innerClassName': null,
              'enableComponentsAddProps': ['SvgImage', 'Hotspots']
            },
            'children': [{
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': false,
                'width': 1920,
                'height': 1080,
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['SvgImage']
              },
              'children': [{
                'component': 'SvgImage',
                'jsoneditor': {
                  'schema': 'image',
                  'name': 'Background',
                  'update': [{
                    'id': 'root_area_background',
                    'data': {
                      'deep': 1,
                      'key': 'props'
                    }
                  }]
                },
                'props': {
                  'id': 'root_area_background',
                  'enableSetDimensions': [{
                    'id': 'parent',
                    'keepwidth': false
                  }, {
                    'id': 'root',
                    'keepwidth': true
                  }],
                  'image': 'https://rematech.carco.hu/demo/flatchooser-app-3/zoomselect_dummy_start.jpg',
                  'filters': {
                    'colorise': 'rgba(0,0,0,0)',
                    'hue': 0,
                    'saturation': 0,
                    'contrast': 0,
                    'blur': 0,
                    'red': 255,
                    'blue': 255,
                    'green': 255,
                    'opacity': 1
                  },
                  'width': 1920,
                  'height': 1080,
                  'containerStyle': {
                    'backgroundColor': 'rgba(0,0,0,0)'
                  }
                }
              }]
            }, {
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': true,
                'relativewidth': '100%',
                'relativeheight': '100%',
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['Hotspots']
              },
              'children': [{
                'component': 'Hotspots',
                'jsoneditor': {
                  'schema': 'hotspot',
                  'name': 'Hotspots',
                  'update': [{
                    'id': 'hotspots_area',
                    'data': {
                      'deep': 1,
                      'key': 'props'
                    }
                  }]
                },
                'props': {
                  'id': 'hotspots_area',
                  'editable': {
                    'resize': 1,
                    'position': 1
                  },
                  'hotspots': [{
                    'props': {
                      'view_id': 'view_1',
                      'points': [[526.972027972028, 9.97978227060652], [977.0629370629371, 348.74834659870476], [977.0629370629371, 933.9968895800934], [528.6713286713286, 977.1228615863142], [134.2657342657343, 921.6951788491448], [133.56643356643357, 480.49766718507]],
                      'effect': ''
                    }
                  }]
                }
              }]
            }]
          }]
        }, {
          'name': '',
          'object_id': 'object_1',
          'key': 'view_1',
          'elements': [{
            'component': 'Root',
            'props': {
              'id': 'root_view_1',
              'scale': false,
              'width': 1920,
              'height': 1080,
              'customStyle': {
                'backgroundColor': 'rgba(0,0,0,0)'
              },
              'innerClassName': null,
              'enableComponentsAddProps': ['SvgImage', 'Hotspots']
            },
            'children': [{
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': false,
                'width': 1920,
                'height': 1080,
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['SvgImage']
              },
              'children': [{
                'component': 'SvgImage',
                'jsoneditor': {
                  'schema': 'image',
                  'name': 'Background',
                  'update': [{
                    'id': 'root_view_1_background',
                    'data': {
                      'deep': 1,
                      'key': 'props'
                    }
                  }]
                },
                'props': {
                  'id': 'root_view_1_background',
                  'enableSetDimensions': [{
                    'id': 'parent',
                    'keepwidth': false
                  }, {
                    'id': 'root',
                    'keepwidth': true
                  }],
                  'image': 'https://rematech.carco.hu/demo/flatchooser-app-3/zoomselect_dummy_building1.jpg',
                  'filters': {
                    'colorise': 'rgba(0,0,0,0)',
                    'hue': 0,
                    'saturation': 0,
                    'contrast': 0,
                    'blur': 0,
                    'red': 255,
                    'blue': 255,
                    'green': 255,
                    'opacity': 1
                  },
                  'width': 1920,
                  'height': 1080,
                  'containerStyle': {
                    'backgroundColor': 'rgba(0,0,0,0)'
                  }
                }
              }]
            }, {
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': true,
                'relativewidth': '100%',
                'relativeheight': '100%',
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['Hotspots']
              },
              'children': [{
                'component': 'Hotspots',
                'jsoneditor': {
                  'schema': 'hotspot',
                  'name': 'Hotspots',
                  'update': [{
                    'id': 'hotspots_view_1',
                    'data': {
                      'deep': 2,
                      'key': 'hotspots'
                    }
                  }]
                },
                'props': {
                  'id': 'hotspots_view_1',
                  'editable': {
                    'resize': 1,
                    'position': 1
                  },
                  'hotspots': [{
                    'props': {
                      'view_id': 'view_3',
                      'points': [[907.1337579617834, 844.8697621744055], [1350.3312101910828, 863.6625141562854], [1351.5541401273886, 956.1007927519819], [907.3662197674937, 1003.8406922533637], [507.73885350318477, 947.2933182332956], [507.2929936305734, 876.3533408833523]],
                      'effect': ''
                    }
                  }, {
                    'props': {
                      'view_id': 'view_4',
                      'points': [[903.1847133757962, 683.1257078142696], [1349.2738853503183, 765.0736126840318], [1350.2738853503183, 863.6274065685164], [905.4076433121019, 846.5039637599094], [507.5159235668791, 876.3533408833523], [506.2929936305734, 801.7440543601359]],
                      'effect': ''
                    }
                  }, {
                    'props': {
                      'view_id': 'view_5',
                      'points': [[505.68132009177106, 726.264998061824], [903.6076984065729, 518.2765979190515], [1350.355179771856, 663.5788077557443], [1349.966662526835, 764.1003330231099], [903.0612813370473, 684.238285323436], [506.3004567683813, 801.7077546680842]],
                      'effect': ''
                    }
                  }]
                }
              }]
            }]
          }]
        }, {
          'name': '',
          'object_id': 'object_2',
          'key': 'view_3',
          'elements': [{
            'component': 'Root',
            'props': {
              'id': 'root_view_3',
              'scale': false,
              'width': 1920,
              'height': 1080,
              'customStyle': {
                'backgroundColor': 'rgba(0,0,0,0)'
              },
              'innerClassName': null,
              'enableComponentsAddProps': ['SvgImage', 'Hotspots']
            },
            'children': [{
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': false,
                'width': 850,
                'height': 480,
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['SvgImage']
              },
              'children': [{
                'component': 'SvgImage',
                'jsoneditor': {
                  'schema': 'image',
                  'name': 'Background',
                  'update': [{
                    'id': 'root_view_3_background',
                    'data': {
                      'deep': 1,
                      'key': 'props'
                    }
                  }]
                },
                'props': {
                  'id': 'root_view_3_background',
                  'enableSetDimensions': [{
                    'id': 'parent',
                    'keepwidth': false
                  }, {
                    'id': 'root',
                    'keepwidth': true
                  }],
                  'image': 'https://rematech.carco.hu/demo/flatchooser-app-3/zoomselect_flat.png',
                  'filters': {
                    'colorise': 'rgba(0,0,0,0)',
                    'hue': 0,
                    'saturation': 0,
                    'contrast': 0,
                    'blur': 0,
                    'red': 255,
                    'blue': 255,
                    'green': 255,
                    'opacity': 1
                  },
                  'width': 850,
                  'height': 480,
                  'containerStyle': {
                    'backgroundColor': 'rgba(0,0,0,0)'
                  }
                }
              }]
            }, {
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': true,
                'relativewidth': '100%',
                'relativeheight': '100%',
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['Hotspots']
              },
              'children': [{
                'component': 'Hotspots',
                'jsoneditor': {
                  'schema': 'hotspot',
                  'name': 'Hotspots',
                  'update': [{
                    'id': 'hotspots_view_3',
                    'data': {
                      'deep': 2,
                      'key': 'hotspots'
                    }
                  }]
                },
                'props': {
                  'id': 'hotspots_view_3',
                  'editable': {
                    'resize': 1,
                    'position': 1
                  },
                  'hotspots': []
                }
              }]
            }]
          }]
        }, {
          'name': '',
          'object_id': 'object_3',
          'key': 'view_4',
          'elements': [{
            'component': 'Root',
            'props': {
              'id': 'root_view_4',
              'scale': false,
              'width': 1920,
              'height': 1080,
              'customStyle': {
                'backgroundColor': 'rgba(0,0,0,0)'
              },
              'innerClassName': null,
              'enableComponentsAddProps': ['SvgImage', 'Hotspots']
            },
            'children': [{
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': false,
                'width': 850,
                'height': 480,
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['SvgImage']
              },
              'children': [{
                'component': 'SvgImage',
                'jsoneditor': {
                  'schema': 'image',
                  'name': 'Background',
                  'update': [{
                    'id': 'root_view_4_background',
                    'data': {
                      'deep': 1,
                      'key': 'props'
                    }
                  }]
                },
                'props': {
                  'id': 'root_view_4_background',
                  'enableSetDimensions': [{
                    'id': 'parent',
                    'keepwidth': false
                  }, {
                    'id': 'root',
                    'keepwidth': true
                  }],
                  'image': 'https://rematech.carco.hu/demo/flatchooser-app-3/zoomselect_flat.png',
                  'filters': {
                    'colorise': 'rgba(0,0,0,0)',
                    'hue': 0,
                    'saturation': 0,
                    'contrast': 0,
                    'blur': 0,
                    'red': 255,
                    'blue': 255,
                    'green': 255,
                    'opacity': 1
                  },
                  'width': 850,
                  'height': 480,
                  'containerStyle': {
                    'backgroundColor': 'rgba(0,0,0,0)'
                  }
                }
              }]
            }, {
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': true,
                'relativewidth': '100%',
                'relativeheight': '100%',
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['Hotspots']
              },
              'children': [{
                'component': 'Hotspots',
                'jsoneditor': {
                  'schema': 'hotspot',
                  'name': 'Hotspots',
                  'update': [{
                    'id': 'hotspots_view_4',
                    'data': {
                      'deep': 2,
                      'key': 'hotspots'
                    }
                  }]
                },
                'props': {
                  'id': 'hotspots_view_4',
                  'editable': {
                    'resize': 1,
                    'position': 1
                  },
                  'hotspots': []
                }
              }]
            }]
          }]
        }, {
          'name': '',
          'object_id': 'object_4',
          'key': 'view_5',
          'elements': [{
            'component': 'Root',
            'props': {
              'id': 'root_view_5',
              'scale': false,
              'width': 1920,
              'height': 1080,
              'customStyle': {
                'backgroundColor': 'rgba(0,0,0,0)'
              },
              'innerClassName': null,
              'enableComponentsAddProps': ['SvgImage', 'Hotspots']
            },
            'children': [{
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': false,
                'width': 850,
                'height': 480,
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['SvgImage']
              },
              'children': [{
                'component': 'SvgImage',
                'jsoneditor': {
                  'schema': 'image',
                  'name': 'Background',
                  'update': [{
                    'id': 'root_view_5_background',
                    'data': {
                      'deep': 1,
                      'key': 'props'
                    }
                  }]
                },
                'props': {
                  'id': 'root_view_5_background',
                  'enableSetDimensions': [{
                    'id': 'parent',
                    'keepwidth': false
                  }, {
                    'id': 'root',
                    'keepwidth': true
                  }],
                  'image': 'https://rematech.carco.hu/demo/flatchooser-app-3/zoomselect_flat.png',
                  'filters': {
                    'colorise': 'rgba(0,0,0,0)',
                    'hue': 0,
                    'saturation': 0,
                    'contrast': 0,
                    'blur': 0,
                    'red': 255,
                    'blue': 255,
                    'green': 255,
                    'opacity': 1
                  },
                  'width': 850,
                  'height': 480,
                  'containerStyle': {
                    'backgroundColor': 'rgba(0,0,0,0)'
                  }
                }
              }]
            }, {
              'component': 'Container',
              'props': {
                'autosize': 'contain',
                'scale': true,
                'relativewidth': '100%',
                'relativeheight': '100%',
                'autopositionx': 'center',
                'autopositiony': 'center',
                'boundingx': 'center',
                'boundingy': 'center',
                'customStyle': {
                  'backgroundColor': 'rgba(0,0,0,0)'
                },
                'innerClassName': null,
                'enableComponentsAddProps': ['Hotspots']
              },
              'children': [{
                'component': 'Hotspots',
                'jsoneditor': {
                  'schema': 'hotspot',
                  'name': 'Hotspots',
                  'update': [{
                    'id': 'hotspots_view_5',
                    'data': {
                      'deep': 2,
                      'key': 'hotspots'
                    }
                  }]
                },
                'props': {
                  'id': 'hotspots_view_5',
                  'editable': {
                    'resize': 1,
                    'position': 1
                  },
                  'hotspots': []
                }
              }]
            }]
          }]
        }]
      }
    }
  }
};
var _default = defaultAppData;
exports["default"] = _default;